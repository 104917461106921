import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { Table, buildColumnProps, SkeletonLoader, Widget } from "@simplicate/ui";
import { useMemo, useCallback } from "react";
import { useDashboardContext } from "../../components/Dashboard";
import { CubeDimension, cubeDimensionToKey } from "../../types";

export type TableWidgetColumn = Parameters<typeof buildColumnProps>[0] & {
  dimension: CubeDimension;
  format?: "currency" | "number" | "percentage";
};

type TableWidgetProps = {
  title: string;
  columns: readonly TableWidgetColumn[];
};

export const TableWidget = ({ title, columns }: TableWidgetProps) => {
  const {
    state: { order },
    actions: { orderBy },
    queryResult: { resultSet, isLoading },
  } = useDashboardContext();
  const dimensions = useMemo(() => columns.map((column) => column.dimension), [columns]);

  const formattedColumns = useMemo(() => {
    return columns.map((column: TableWidgetColumn) => {
      const fieldName = cubeDimensionToKey(column.dimension);

      return {
        ...column,
        body: (record: { [fieldName: string]: boolean | number | string }) => {
          const value = record[fieldName];

          if (column.format) {
            switch (column.format) {
              case "number":
                return typeof value === "number" ? <NumberFormat value={value} decimalScale={2} /> : value;
              case "currency":
                return typeof value === "number" ? <CurrencyFormat value={value} decimalScale={2} /> : value;
              case "percentage":
                return typeof value === "number" ? <NumberFormat suffix="%" value={value} decimalScale={0} /> : value;
            }
          }

          return value;
        },
      };
    });
  }, [columns]);

  const [sortField, sortOrder] = useMemo(() => {
    if (!order) return [undefined, undefined];

    return [order.key, order.direction === "asc" ? 1 : -1] as const;
  }, [order]);

  const value = useMemo(() => {
    if (isLoading) return new Array(10).fill(null).map(() => ({}));
    if (!resultSet) return [];

    return resultSet.tablePivot().map((row) => {
      const result: Record<string, unknown> = {};

      dimensions.forEach((dimension) => {
        const key = cubeDimensionToKey(dimension);

        result[key] = row[key];
      });

      return result;
    });
  }, [dimensions, isLoading, resultSet]);

  const handleSort = useCallback(({ sortField }: { sortField: string }) => orderBy(sortField), [orderBy]);

  return (
    <Widget title={title} variant="table">
      <Table value={value} sortField={sortField} sortOrder={sortOrder} onSort={handleSort}>
        {formattedColumns.map((column) => (
          <Table.Column
            key={cubeDimensionToKey(column.dimension)}
            {...buildColumnProps({
              ...column,
              field: cubeDimensionToKey(column.dimension),
              body: isLoading ? <SkeletonLoader /> : column.body,
            })}
          />
        ))}
      </Table>
    </Widget>
  );
};
