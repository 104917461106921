import { BinaryFilter } from "@cubejs-client/core";
import { endOfWeek, startOfWeek } from "date-fns";

export type LabeledFilter = {
  label: string;
  filter: BinaryFilter;
};

export type DashboardState = {
  filters: LabeledFilter[];
  search: string;
  order?: {
    key: string;
    direction: "asc" | "desc";
  };
  dateRange: {
    start: Date;
    end: Date;
  };
  cubeQuery?: {
    filters: BinaryFilter[];
    order?: { [key: string]: "asc" | "desc" };
  };
};

type AddFilterAction = {
  type: "ADD_FILTER";
  filter: BinaryFilter;
  label: string;
};

type ResetAction = {
  type: "RESET";
};

type OrderByAction = {
  type: "ORDER_BY";
  key: string;
};

type RemoveFilterAction = {
  type: "REMOVE_FILTER";
  label: string;
};

type SetSearchAction = {
  type: "SET_SEARCH";
  search: string;
};

type SetStartDateAction = {
  type: "SET_START_DATE";
  date: Date;
};

type SetEndDateAction = {
  type: "SET_END_DATE";
  date: Date;
};

export type ReducerAction =
  | AddFilterAction
  | OrderByAction
  | RemoveFilterAction
  | ResetAction
  | SetEndDateAction
  | SetSearchAction
  | SetStartDateAction;

function buildOrderingState(
  { order }: Pick<DashboardState, "order">,
  action: OrderByAction,
): Pick<DashboardState, "order"> {
  if (!order) {
    return { order: { key: action.key, direction: "asc" } };
  }

  const { key, direction } = order;

  return {
    order: {
      key: action.key,
      direction: key === action.key && direction === "asc" ? "desc" : "asc",
    },
  };
}

function dashboardReducer(state: DashboardState, action: ReducerAction): Omit<DashboardState, "cubeQuery"> {
  switch (action.type) {
    case "ADD_FILTER":
      return {
        ...state,
        filters: [...state.filters, { filter: action.filter, label: action.label }],
      };

    case "RESET":
      return {
        ...state,
        filters: [],
        dateRange: {
          start: startOfWeek(new Date()),
          end: endOfWeek(new Date()),
        },
      };

    case "ORDER_BY":
      return {
        ...state,
        ...buildOrderingState(state, action),
      };

    case "REMOVE_FILTER":
      return {
        ...state,
        filters: state.filters.filter((filter) => filter.label !== action.label),
      };

    case "SET_END_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          end: action.date,
        },
      };

    case "SET_SEARCH":
      return {
        ...state,
        search: action.search,
      };

    case "SET_START_DATE":
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start: action.date,
        },
      };
  }
}

export function dashboardStateReducer(state: DashboardState, action: ReducerAction): DashboardState {
  const newState = dashboardReducer(state, action);

  return {
    ...newState,
    cubeQuery: {
      filters: newState.filters.map(({ filter }) => filter),
      order: newState.order ? { [newState.order.key]: newState.order.direction } : undefined,
    },
  };
}
