import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { memo } from "react";

type HourTypeActionDropdownProps = {
  identifier: string;
  onClickMoveToTop: (identifier: string) => void;
  onClickMoveToBottom: (identifier: string) => void;
  onClickRemove: (identifier: string) => void;
};

export const HourTypeActionDropdown = memo(function HourTypeActionDropdown({
  identifier,
  onClickMoveToTop,
  onClickMoveToBottom,
  onClickRemove,
}: HourTypeActionDropdownProps) {
  const { t } = useTranslation("project_services");

  return (
    <ActionDropdown testId="hour-type-actions">
      <ActionDropdown.Action onClick={() => onClickMoveToTop(identifier)}>
        <Icon icon="arrowUp" />
        {t("move_to_top")}
      </ActionDropdown.Action>
      <ActionDropdown.Action onClick={() => onClickMoveToBottom(identifier)}>
        <Icon icon="arrowDown" />
        {t("move_to_bottom")}
      </ActionDropdown.Action>
      <ActionDropdown.Action onClick={() => onClickRemove(identifier)}>
        <Icon icon="trash" />
        {t("remove_from_grid")}
      </ActionDropdown.Action>
    </ActionDropdown>
  );
});
