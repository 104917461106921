import { useTranslation } from "@simplicate/translations";
import { Button, Datepicker, Tag, TagList } from "@simplicate/ui";
import { format } from "date-fns";
import { useCallback, useMemo } from "react";
import { useDashboardContext, LabeledFilter } from "../../components/Dashboard";
import { DimensionValueSelect } from "../../components/DimensionValueSelect";
import { CubeDimension, cubeDimensionToKey } from "../../types";
import styles from "./FilterWidget.module.scss";

export type DimensionFilterConfig = {
  dimension: CubeDimension;
  placeholder?: string;
  filterFormat?: (value: unknown) => string;
};

type FilterWidgetProps = {
  dimensions: DimensionFilterConfig[];
};

export const FilterWidget = ({ dimensions }: FilterWidgetProps) => {
  const { t } = useTranslation("insights");
  const {
    actions: { removeFilter, addFilter, resetState, setEndDate, setStartDate },
    state: {
      filters,
      dateRange: { start, end },
    },
  } = useDashboardContext();

  const handleSelectDimensionValue = useCallback(
    (filterLabel: string, value: string, dimension: CubeDimension) => {
      addFilter(filterLabel, {
        member: cubeDimensionToKey(dimension),
        operator: "equals",
        values: [value],
      });
    },
    [addFilter],
  );

  const dateRangeFilterLabel = useMemo(() => {
    const startDateLabel = format(start, "dd MMM yyyy");
    const endDateLabel = format(end, "dd MMM yyyy");

    return t("filters.time_filter", { start: startDateLabel, end: endDateLabel });
  }, [start, end, t]);

  return (
    <div className={styles.filterWidgetContainer}>
      <div className={styles.dropdownsRow}>
        <Datepicker value={start} onChange={setStartDate} />
        <Datepicker value={end} onChange={setEndDate} />
        {dimensions.map(({ dimension, placeholder, filterFormat }) => (
          <DimensionValueSelect
            key={cubeDimensionToKey(dimension)}
            placeholder={placeholder}
            dimension={dimension}
            onChange={(value) => {
              const valueAsLabel = filterFormat ? filterFormat(value) : value;

              handleSelectDimensionValue(valueAsLabel, value, dimension);
            }}
          />
        ))}
      </div>
      <TagList>
        <Tag text={dateRangeFilterLabel} />
        {filters.map(({ label }: LabeledFilter) => {
          return <Tag key={label} text={label} onClose={() => removeFilter(label)} />;
        })}
        <Button variant="subtle" size="small" iconProps={{ icon: "times" }} text="Reset filters" onClick={resetState} />
      </TagList>
    </div>
  );
};
