import { simplicateApiV3 } from "../components";
import { type CostType } from "../types";
import { HourTypeFromApi } from "./HourType";
import { InvoiceMethod } from "./InvoiceMethod";
import { MoneyFromAPI } from "./utils";

export type HourTypeInCreateServiceBody = {
  defaultHourTypeIdentifier: string;
  tariff: MoneyFromAPI;
  amountOfHours: number;
  isInvoicable: boolean | undefined;
};

export type HourTypeInService = {
  defaultHourType: HourTypeFromApi;
  tariff: MoneyFromAPI;
  amountOfHours: number;
  isInvoicable: boolean | undefined;
};

export type CostTypeInService = {
  identifier: string;
  defaultCostType: CostType;
  description?: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number;
  isInvoiceable: boolean;
  margin: number;
  total: MoneyFromAPI;
};

type BuildServiceBody = {
  defaultServiceIdentifier: string;
  projectIdentifier: string;
};

type ProjectServiceBody = {
  projectIdentifier: string;
  defaultServiceIdentifier: string;
  invoiceMethod: InvoiceMethod;
  description: string;
  explanation?: string;
  revenueGroupIdentifier: string;
  vatCodeIdentifier: string;
  timeFrame: {
    startDate: string | null;
    endDate: string | null;
  };
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
  hourTypeConfiguration: {
    hourTypes: HourTypeInCreateServiceBody[];
    employeeTariffs: object[];
    specifiedTotal: MoneyFromAPI;
  };
  costTypes: object[];
};

type FixedPriceServiceValues = {
  invoiceableFrom: string | null;
  invoiceInInstallments: boolean;
};

export type CreateProjectServiceBody = ProjectServiceBody | (FixedPriceServiceValues & ProjectServiceBody);

export type ProjectService = {
  defaultServiceIdentifier: string;
  description: string;
  explanation?: string;
  invoiceMethod?: InvoiceMethod;
  revenueGroup?: {
    identifier: string;
    name: string;
  };
  vatCode?: {
    identifier: string;
    name: string;
  };
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
  hourTypeConfiguration: {
    hourTypes: HourTypeInService[];
  };
  costTypes: CostTypeInService[];
};

const endpoints = simplicateApiV3.injectEndpoints({
  endpoints: (builder) => ({
    buildProjectService: builder.query<ProjectService, BuildServiceBody>({
      query: (body) => ({
        url: "/projects.service.build",
        method: "POST",
        body,
      }),
    }),
    createProjectService: builder.mutation<ProjectService, CreateProjectServiceBody>({
      query: (projectService) => {
        return {
          url: "/projects.service.create",
          method: "POST",
          body: projectService,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useBuildProjectServiceQuery, useCreateProjectServiceMutation } = endpoints;
