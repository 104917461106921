import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  type InvoiceMethod,
  Big,
  type Money,
  type ProjectService,
  useBuildProjectServiceQuery,
  useCreateProjectServiceMutation,
} from "@simplicate/api-client";
import { useFormik, FormikErrors } from "formik";
import { useCallback, useEffect } from "react";
import { buildValidationSchema } from "./projectServiceFormValidation";
import { transformToCostTypeInForm } from "./transformCostTypes";
import { transformFormToCreateProjectServiceBody } from "./transformFormToCreateProjectServiceBody";
import { transformToHourTypeInForm } from "./transformHourTypes";

export type HourTypeInForm = {
  identifier: string;
  name: string;
  tariff: Money | undefined;
  amount: number | undefined;
  total: Money;
  isNewEntry?: boolean;
};

type CostTypeWithPricePerUnit = {
  usePricePerUnit: true;
  unit: string;
  quantity: number;
};

type CostTypeWithoutPricePerUnit = {
  usePricePerUnit: false;
  unit?: never;
  quantity?: never;
};

export type CostTypeInForm = {
  identifier: string;
  name: string;
  purchasePrice: Money | undefined;
  sellingPrice: Money | undefined;
  margin: number;
  total: Money;
} & (CostTypeWithoutPricePerUnit | CostTypeWithPricePerUnit);

export type ValidHourTypeInForm = Omit<HourTypeInForm, "amount" | "isNewEntry" | "tariff"> & {
  tariff: Money;
  amount: number;
};

export type ProjectServiceForm = {
  defaultService?: string;
  invoiceMethod?: InvoiceMethod;
  description?: string;
  explanation?: string;
  timeframe: {
    startDate?: Date;
    endDate?: Date;
  };
  revenueGroup?: string;
  vatCode?: string;
  invoiceableFrom?: Date;
  invoiceInInstallments?: boolean;
  canRegisterHours?: boolean;
  canRegisterCosts?: boolean;
  hourTypes?: HourTypeInForm[];
  costTypes?: CostTypeInForm[];
  costsOrHours?: boolean;
};

export interface ProjectServiceFormErrors extends FormikErrors<ProjectServiceForm> {
  costsOrHours?: string;
}

export type ValidProjectServiceForm = {
  defaultService: string;
  invoiceMethod: InvoiceMethod;
  description: string;
  explanation: string | undefined;
  timeframe: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  revenueGroup: string;
  vatCode: string;
  invoiceableFrom?: Date;
  invoiceInInstallments?: boolean;
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
  hourTypes?: ValidHourTypeInForm[];
};

type UseProjectServiceFormProps = {
  initialValues?: ProjectServiceForm;
  projectId?: string;
};

export const useProjectServiceForm = ({ initialValues, projectId }: UseProjectServiceFormProps) => {
  const validationSchema = buildValidationSchema();

  /* istanbul ignore next -- RTK is mocked in tests */
  const [createProjectService] = useCreateProjectServiceMutation({ selectFromResult: () => ({}) });

  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors } =
    useFormik<ProjectServiceForm>({
      initialValues: {
        ...initialValues,
        timeframe: initialValues?.timeframe ?? { startDate: undefined, endDate: undefined },
      },
      onSubmit: async (values) => {
        if (!projectId) {
          return;
        }
        await createProjectService(
          transformFormToCreateProjectServiceBody(values as ValidProjectServiceForm, projectId),
        );
      },
      validationSchema,
    });

  const setFieldValueAndHandleErrors = useCallback(
    async (fieldName: string, fieldValue: unknown) => {
      const errors = await setFieldValue(fieldName, fieldValue);

      if (errors) {
        setErrors(errors);
      }
    },
    [setErrors, setFieldValue],
  );

  const setDefaultService = useCallback(
    (defaultService: string) => {
      void setFieldTouched("defaultService");
      void setFieldValueAndHandleErrors("defaultService", defaultService);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setInvoiceMethod = useCallback(
    (invoiceMethod: InvoiceMethod) => {
      void setFieldTouched("invoiceMethod");
      void setFieldValueAndHandleErrors("invoiceMethod", invoiceMethod);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setDescription = useCallback(
    (description: string) => {
      void setFieldTouched("description");
      void setFieldValueAndHandleErrors("description", description);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setExplanation = useCallback(
    (explanation: string) => {
      void setFieldTouched("explanation");
      void setFieldValueAndHandleErrors("explanation", explanation);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setInvoiceableFrom = useCallback(
    (invoiceableFrom: Date | undefined) => {
      void setFieldTouched("invoiceableFrom");
      void setFieldValueAndHandleErrors("invoiceableFrom", invoiceableFrom);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setInvoiceInInstallments = useCallback(
    (invoiceInInstallments: boolean) => {
      void setFieldTouched("invoiceInInstallments");
      void setFieldValueAndHandleErrors("invoiceInInstallments", invoiceInInstallments);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setStartDate = useCallback(
    (startDate: Date | undefined) => {
      void setFieldTouched("timeframe.startDate");
      void setFieldValueAndHandleErrors("timeframe.startDate", startDate);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setEndDate = useCallback(
    (endDate: Date | undefined) => {
      void setFieldTouched("timeframe.endDate");
      void setFieldValueAndHandleErrors("timeframe.endDate", endDate);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setRevenueGroup = useCallback(
    (revenueGroup: string) => {
      void setFieldTouched("revenueGroup");
      void setFieldValueAndHandleErrors("revenueGroup", revenueGroup);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setVatCode = useCallback(
    (VATCode: string) => {
      void setFieldTouched("vatCode");
      void setFieldValueAndHandleErrors("vatCode", VATCode);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setCanRegisterHours = useCallback(
    (newValue: boolean) => {
      void setFieldTouched("canRegisterHours");
      void setFieldValueAndHandleErrors("canRegisterHours", newValue);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setCanRegisterCosts = useCallback(
    (newValue: boolean) => {
      void setFieldTouched("canRegisterCosts");
      void setFieldValueAndHandleErrors("canRegisterCosts", newValue);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const addNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []),
      {
        identifier: "",
        name: "",
        amount: 0,
        tariff: { amount: Big(0), currency: "EUR" },
        total: { amount: Big(0), currency: "EUR" },
        isNewEntry: true,
      } satisfies HourTypeInForm,
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const removeNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const addHourType = useCallback(
    (hourType: HourTypeInForm) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors("hourTypes", [
        ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
        hourType,
      ]);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const removeHourTypes = useCallback(
    (identifiers: string[]) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.filter((hourType) => !identifiers.includes(hourType.identifier)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setAmountForHourType = useCallback(
    (hourTypeIdentifier: string, amount: number | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.identifier === hourTypeIdentifier
            ? {
                ...hourType,
                amount,
                total: { ...hourType.total, amount: hourType.tariff?.amount?.mul(amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setTariffForHourType = useCallback(
    (hourTypeIdentifier: string, tariffAmount: Big | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.identifier === hourTypeIdentifier
            ? {
                ...hourType,
                tariff: tariffAmount ? { ...hourType.tariff, amount: tariffAmount } : undefined,
                total: { ...hourType.total, amount: tariffAmount?.mul(hourType.amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setValuesFromProjectService = useCallback(
    (buildProjectService: ProjectService) => {
      if (!touched.invoiceMethod) {
        void setFieldValueAndHandleErrors("invoiceMethod", buildProjectService.invoiceMethod);
      }
      if (!touched.description) {
        void setFieldValueAndHandleErrors("description", buildProjectService.description);
      }
      if (!touched.explanation) {
        void setFieldValueAndHandleErrors("explanation", buildProjectService.explanation);
      }
      if (!touched.revenueGroup) {
        void setFieldValueAndHandleErrors("revenueGroup", buildProjectService.revenueGroup?.identifier);
      }
      if (!touched.vatCode) {
        void setFieldValueAndHandleErrors("vatCode", buildProjectService.vatCode?.identifier);
      }
      if (!touched.canRegisterHours) {
        void setFieldValueAndHandleErrors("canRegisterHours", buildProjectService.canRegisterHours);
      }
      if (!touched.canRegisterCosts) {
        void setFieldValueAndHandleErrors("canRegisterCosts", buildProjectService.canRegisterCosts);
      }
      if (!touched.hourTypes) {
        void setFieldValueAndHandleErrors(
          "hourTypes",

          buildProjectService.hourTypeConfiguration.hourTypes.map((hourTypeInService) =>
            transformToHourTypeInForm(hourTypeInService),
          ),
        );
      }
      if (!touched.costTypes) {
        void setFieldValueAndHandleErrors(
          "costTypes",
          buildProjectService.costTypes.map((costTypeInService) => transformToCostTypeInForm(costTypeInService)),
        );
      }
    },
    [
      setFieldValueAndHandleErrors,
      touched.vatCode,
      touched.explanation,
      touched.invoiceMethod,
      touched.description,
      touched.revenueGroup,
      touched.canRegisterHours,
      touched.canRegisterCosts,
      touched.hourTypes,
      touched.costTypes,
    ],
  );

  useBuildProjectService({ defaultServiceValue: values.defaultService, projectId, setValuesFromProjectService });

  return {
    values,
    errors: errors as ProjectServiceFormErrors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setDescription,
    setExplanation,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setStartDate,
    setEndDate,
    setRevenueGroup,
    setVatCode,
    setCanRegisterHours,
    setCanRegisterCosts,
    setAmountForHourType,
    setTariffForHourType,
    addNewHourTypeEntry,
    removeNewHourTypeEntry,
    addHourType,
    removeHourTypes,
    handleSubmit,
  };
};

type useBuildProjectServiceProps = {
  projectId: string | undefined;
  defaultServiceValue: string | undefined;
  setValuesFromProjectService: (projectService: ProjectService) => void;
};

const useBuildProjectService = ({
  defaultServiceValue,
  projectId,
  setValuesFromProjectService,
}: useBuildProjectServiceProps) => {
  const { data: projectService, isSuccess } = useBuildProjectServiceQuery(
    defaultServiceValue && projectId
      ? { defaultServiceIdentifier: defaultServiceValue, projectIdentifier: projectId }
      : skipToken,
  );

  useEffect(() => {
    if (!isSuccess || projectService === undefined) {
      return;
    }
    setValuesFromProjectService(projectService);
  }, [isSuccess, projectService, setValuesFromProjectService]);
};
