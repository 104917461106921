import { GroupedServicesManager } from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { Page, Button, Dropdown, ListItem, List } from "@simplicate/ui";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddServiceGroupModal } from "./components/AddServiceGroupModal";
import { useSalesServicesPageData } from "./SalesServicesPage.hooks";
import styles from "./SalesServicesPage.module.scss";

export const SalesServicesPage = () => {
  const { id: salesId } = useParams<{ id: string }>();
  const { treeData, setAndSaveTreeData, rootId, groupServicesByInvoiceMethod } = useSalesServicesPageData(salesId);
  const { t } = useTranslation("sales_services");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <Page>
      <header className={styles.pageHeader}>
        <h2 className={styles.title}>{t("services_page_title")}</h2>
        <div className={styles.buttonDropdownWrapper}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            text={t("add_services_dropdown")}
            iconProps={{ icon: "chevronDown" }}
          />

          {dropdownOpen && (
            <div className={styles.dropdownContainer}>
              <Dropdown
                isOpen={dropdownOpen}
                width="auto"
                variant="clean"
                onClickOutside={/* istanbul ignore next */ () => setDropdownOpen(false)}
              >
                <List>
                  <ListItem>
                    <Button
                      variant="invisible"
                      text={t("add_service_button")}
                      onClick={() => {
                        navigate(`/sales/${salesId}/services/new`);
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="invisible"
                      text={t("add_services_group_button")}
                      onClick={() => {
                        setModalKey((prev) => prev + 1);
                        setModalOpen(true);
                        setDropdownOpen(false);
                      }}
                    />
                  </ListItem>
                </List>
              </Dropdown>
            </div>
          )}
        </div>
      </header>

      {treeData && (
        <GroupedServicesManager
          treeData={treeData}
          onChangeTree={setAndSaveTreeData}
          rootId={rootId}
          onAutoGroup={groupServicesByInvoiceMethod}
        />
      )}
      {salesId && (
        <AddServiceGroupModal salesId={salesId} isOpen={modalOpen} onClose={() => setModalOpen(false)} key={modalKey} />
      )}
    </Page>
  );
};
