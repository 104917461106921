import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Page } from "@simplicate/ui";
import { NavCard } from "../../components/NavCard/NavCard";
import styles from "./IndexPage.module.scss";

export const IndexPage = () => {
  const { t } = useTranslation("insights");

  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");
  const { granted: hasHRMReportingPermission } = usePermission("hrm", "rapportage");
  const { granted: hasSalesReportingPermission } = usePermission("acquisition", "rapportage");

  return (
    <Page>
      <h2 className={styles.indexPageTitle}>{t("index_page_title")}</h2>
      <div className={styles.indexPageLayout}>
        {hasSalesReportingPermission && (
          <NavCard
            dashboardId="hours-reporting"
            title={t("sales_funnel.report_title")}
            module="sales"
            description={t("sales_funnel.report_description")}
          />
        )}
        {hasAccessToHours && hasHoursReportingPermission && (
          <NavCard
            dashboardId="hours-reporting"
            title={t("hours.report_title")}
            module="hours"
            description={t("hours.report_description")}
          />
        )}
        {hasHRMReportingPermission && (
          <NavCard
            dashboardId="hours-reporting"
            title={t("leave.report_title")}
            icon="calendarAlt"
            module="hrm"
            description={t("leave.report_description")}
          />
        )}
        {hasHRMReportingPermission && (
          <NavCard
            dashboardId="hours-reporting"
            title={t("absence.report_title")}
            module="hrm"
            description={t("absence.report_description")}
          />
        )}
      </div>
    </Page>
  );
};
