import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Widget.module.scss";

type WidgetProps = PropsWithChildren<{
  title: string;
  variant?: "default" | "table";
}>;

export const Widget = ({ children, title, variant = "default" }: WidgetProps) => {
  return (
    <div className={variant === "table" ? classNames(styles.widget, styles.tableWidget) : styles.widget}>
      <h5 className={styles.title}>{title}</h5>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
