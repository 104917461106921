import { FeatureFlagSwitch } from "@simplicate/api-client";
import { HoursReportPage, IndexPage } from "@simplicate/insights";
import { Navigate } from "react-router-dom";
import { RouteObject } from "../types";

export const insightsRoutes: RouteObject[] = [
  {
    path: "insights",
    children: [
      {
        index: true,
        element: (
          <FeatureFlagSwitch featureFlag="insights" ifEnabled={<IndexPage />} ifDisabled={<Navigate to="/404" />} />
        ),
      },
      {
        path: "hours-reporting",
        element: (
          <FeatureFlagSwitch
            featureFlag="insights"
            ifEnabled={<HoursReportPage />}
            ifDisabled={<Navigate to="/404" />}
          />
        ),
        handle: {
          backNavigationPath: "/insights",
          permissions: [{ module_key: "hours", right_key: "hoursrapportage_all" }],
        },
      },
    ],
    handle: {
      theme: "insights",
      headerTitle: "module_insights",
      permissions: [
        { module_key: "hrm", right_key: "rapportage" },
        { module_key: "acquisition", right_key: "rapportage" },
        { module_key: "hours", right_key: "hoursrapportage_all" },
      ],
    },
  },
];
