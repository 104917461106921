import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Button, Icon } from "@simplicate/ui";
import { colorCommonGrey700 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import styles from "./ServiceGroupHeader.module.scss";

type ServiceGroupHeaderProps = {
  text: string;
  isUngroupedContainer: boolean;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
  deleteCallback?: () => void;
};

export const ServiceGroupHeader = ({
  text,
  isUngroupedContainer,
  onDuplicateGroup,
  onAutoGroup,
  deleteCallback,
}: ServiceGroupHeaderProps) => {
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div className={styles.header} data-testid="service-group-header">
      <div className={styles.headerLeft}>
        <span className={classNames(styles.grip, isUngroupedContainer && styles.hidden)}>
          <Icon icon="grip" />
        </span>
        <h2 className={styles.groupName}>{text}</h2>
      </div>
      <div className={styles.headerRight}>
        {isUngroupedContainer ? (
          onAutoGroup && (
            <Button
              variant="secondary"
              iconProps={{ icon: "wand" }}
              text={t("group_by_invoice_method")}
              onClick={onAutoGroup}
            />
          )
        ) : (
          <div className={styles.headerRight}>
            <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
              <ActionDropdown.Action onClick={onDuplicateGroup}>
                <Icon icon="copy" color={colorCommonGrey700} />
                {t("duplicate")}
              </ActionDropdown.Action>
              <ActionDropdown.Action onClick={deleteCallback} className={styles.deleteButton}>
                <Icon icon="trash" />
                {t("delete")}
              </ActionDropdown.Action>
            </ActionDropdown>
          </div>
        )}
      </div>
    </div>
  );
};
